import Models, { Model } from "../components/Models";
import LandingSection from "../components/LandingSection2";
import { useState } from "react";
import { NextSeo } from "next-seo";
import Image from "next/image";
import logo from "../public/assets/logo.png";

export function Home() {
  const [featuredModels, setFeaturedModels] = useState<Model[]>([]);
  return (
    <div>
      <NextSeo
        title="Dvele Design Studio | Custom Modular Home Manufacturer"
        description={`At Dvele, our mission is to deliver a refreshingly simple client experience from "Hello" to "Welcome Home." We offer a wide-array of customizable, modern pre-fab homes that verifiably exceed all standards of quality and efficiency.`}
      />
      <LandingSection featuredModels={featuredModels} />
      <Models setFeaturedModels={setFeaturedModels} />
    </div>
  );
}

const TemporaryConstructionPage = () =>{
  return <div className="flex items-center justify-center h-screen p-8">
  <div className="flex flex-col items-center">
  <h1>Hello</h1>
  <p>At this time Dvele is focusing on projects with multiple units of single family homes, ADU&apos;s, duplexes and townhomes.</p>
  <p>We are currently in the process of revamping our catalog to reflect these changes.</p>
  <p>If you are a developer or building multiple units, please reach out to us at <a href="https://dvele.com/developers#developer-contact" className="!underline hover:opacity-70 text-accent bold">dvele.com/developers</a></p>
  <a href="https://dvele.com">
  <Image src={logo} alt="Dvele logo image" width={150} priority className="mt-8"/>
  </a></div>
  
  </div>
}
export default TemporaryConstructionPage